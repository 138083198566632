import styled from 'styled-components'

export const EstablishmentCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.establishmentCard.border};

  &:hover {
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.establishmentCard.borderHover};
  }

  &:last-of-type {
    border-bottom: initial;
  }

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    &:last-of-type {
      border-bottom: 1px solid
        ${({ theme }) => theme.colors.establishmentCard.border};
    }

    &:hover {
      &:last-of-type {
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.establishmentCard.borderHover};
      }
    }
  }
`

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.establishmentCard.title};
  font-size: ${({ theme }) => (16 - 2) / theme.fontSizes.root + 'rem'};
  font-weight: 700;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    font-size: ${({ theme }) => (18 - 2) / theme.fontSizes.root + 'rem'};
  }
`

export const Text = styled.p``

export const ButtonWrapper = styled.div`
  margin-top: auto;
  align-self: flex-end;
`
