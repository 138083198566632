import Script from 'next/script'
import { useEffect, useRef } from 'react'

// Styled Components
import { MapBox } from './Map.styles'

const createMap = ({ element, lat, lng, title }: any) => {
  const google = (global as any).google
  var mapOptions = {
    center: {
      lat,
      lng,
    },
    clickableIcons: true,
    disableDoubleClickZoom: false,
    draggable: true,
    fullscreenControl: true,
    keyboardShortcuts: true,
    mapTypeControl: true,
    mapTypeControlOptions: {
      text: 'Default (depends on viewport size etc.)',
      style: 0,
    },
    mapTypeId: 'terrain',
    rotateControl: true,
    scaleControl: true,
    scrollwheel: true,
    streetViewControl: true,
    styles: [
      {
        featureType: 'landscape',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'water',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        stylers: [
          { hue: '#00aaff' },
          { saturation: -100 },
          { gamma: 2.15 },
          { lightness: 12 },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ visibility: 'on' }, { lightness: 24 }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ lightness: 57 }],
      },
    ],
    zoom: 16,
    zoomControl: true,
  }
  var mapElement = element //document.getElementById('ez-map');
  var map = new google.maps.Map(mapElement, mapOptions)
  var marker0 = new google.maps.Marker({
    title,
    icon: 'https://ezmap.co/icons/svgs/location-red.svg',
    position: new google.maps.LatLng(lat, lng),
    map: map,
  })
  var infowindow0 = new google.maps.InfoWindow({
    content: `<h3 class="infoTitle">${title}</h3><p></p>`,
    map: map,
  })
  marker0.addListener('click', function () {
    infowindow0.open(map, marker0)
  })
  infowindow0.close()
  var heatmap = new google.maps.visualization.HeatmapLayer({ data: [] })
  heatmap.setOptions({ opacity: 0.6, dissipating: false, radius: 0 })
  heatmap.setMap(map)
  window.addEventListener('resize', function () {
    var center = map.getCenter()
    google.maps.event.trigger(map, 'resize')
    map.setCenter(center)
  })
}

type Props = {
  lat?: number
  lng?: number
  title: string
}

const Map = ({ lat, lng, title }: Props) => {
  const ref = useRef(null)
  const onLoad = () =>
    lat &&
    lng &&
    (global as any).google &&
    createMap({ element: ref.current, lat, lng, title })

  useEffect(() => {
    onLoad()
  }, [lat, lng])

  return (
    <>
      <Script
        key={title}
        src='https://maps.googleapis.com/maps/api/js?key=AIzaSyC6JijIlTVmit8kIDWj9EA8qIWPgxY49Oo&libraries=visualization'
        onLoad={onLoad}
      />
      <MapBox ref={ref} />
    </>
  )
}

export default Map
